<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="main-box">
				<div class="mem_contents_header account">
					<div class="title-txt">아이디 찾기<br/><span>아이디를 분실 하셨나요?</span></div>
				</div>
				<div class="mem_contents_body">
					<div class="info-box">
						<div class="phone-img account top">
							회원 계정 선택
							<div class="select-box">
								<span v-for="(row, rowIdx) in mberDivList" :key="rowIdx">
									<input type="radio" class="account" name="account" :id="'rdo'+row.mberDivCd" :value="row.mberDivCd" v-model="mberDivCd">
									<label :for="'rdo'+row.mberDivCd">
										<span class="rd"></span>
										{{row.title}}
									</label>
									<span class="account" v-if="mberDivList.length > (rowIdx + 1)">/&nbsp;</span>
								</span>
							</div>
							<div class="phone-info account">
								선택하신 계정으로 아이디찾기를 진행하시겠습니까?
							</div>
						</div>
						<div class="phone-btn account">
							<div class="btn-crt account" @click="pageMove()">다음</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberDivList : [
				{
					mberDivCd : '11',
					title     : '기술인재',
				},
				{
					mberDivCd : '20',
					title     : '수요기업',
				},
				{
					mberDivCd : '31',
					title     : '공급기업',
				},
			],

			mberDivCd : '11'
		}
	}, 

	methods : {
		pageMove() {
			var scrNm = 'MEM002M01';
			if(this.mberDivCd == '20') scrNm = 'MEM002M07';

			this.$router.push({name:scrNm, params:{mberDivCd:this.mberDivCd}});
		}
	}
}
</script>